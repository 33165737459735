import React from "react";
import GenerativeImg from "../assets/images/new/generative.svg";
import StepImg from "../assets/images/step-img(new).svg";
import StepImgVer from "../assets/images/step-img-mob(new).svg";
import { AnimatePresence, motion } from "framer-motion";
const BackstoryAndProduct = () => {
  return (
    <div>
      <div className="row g-0 mt-5 m-1 p-1 px-lg-4 bg-transparent">
        <div className="col-lg-5 col-md-6 mt-5 text-center">
          <img src={GenerativeImg} className="backstory-img" height={280} />
        </div>
        <div className="col-lg-7 col-md-6 mt-lg-5 p-1 px-lg-5 text-start">
          <motion.div
            variants={{
              visible: { opacity: 1, y: 0, transition: { duration: 1 } },
              hidden: { opacity: 0, y: -50 },
            }}
            initial="hidden"
            animate="visible"
            className="fw_700 fs_30 fs_sm_25 light_blue bg-transparent text-start mx-2 my-3 my-sm-0"
          >
            Backstory
          </motion.div>
          <motion.div
            variants={{
              visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
              hidden: { opacity: 0, y: -30 },
            }}
            initial="hidden"
            animate="visible"
            className="fw_700 fs_40 fs_sm_25 text-start px-2"
          >
            The Industry
          </motion.div>
          <motion.div
            variants={{
              visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
              hidden: { opacity: 0, y: -20 },
            }}
            initial="hidden"
            animate="visible"
            className="fw_400 fs_22 fs_sm_18 mt-lg-3 mt-2 px-2 text-color me-lg-5"
          >
            In any Industry where sales/projects are based on “approvals”, they
            usually need to be approved by a chain of managers. Manual approval
            and verification of this process is very cumbersome, making it prone
            to have errors, which causes delay, indirectly leading to a negative
            customer experience.
          </motion.div>
        </div>
      </div>
      <>
        <div className="row g-0 my-lg-5 my-2 text-center">
          <div className="fw_700 fs_40 fs_sm_25 mt-5">Our Products</div>
          <div className="fw_400 fs_22 fs_sm_18 px-3 text-color mt-1 text-start text-md-center">
            We operate on 5 different platforms to make this approval system
            really come alive.
          </div>
          <div className="my-lg-5 my-3">
            <motion.img
              // initial={{ pathLength: 0 }}
              // animate={{ pathLength: 1 }}
              src={StepImg}
              className="d-none d-lg-block mx-auto px-3 px-xl-0 mx-2"
              style={{ maxWidth: "1100px", width: "100%" }}
            />
            <img
              src={StepImgVer}
              className="d-lg-none mx-2"
              style={{ maxWidth: "280px", width: "100%" }}
            />
          </div>
          <div
            className="fw_400 fs_22 fs_sm_18 text-color text-start text-md-center
     mx-md-auto mx-0 mt-1 px-3 p-1 col-12 col-md-10 col-lg-6"
          >
            With the help of Jivini’s platforms, now have the work done the way
            you like it, while you save time for more important work, we get
            things done.
          </div>
        </div>
      </>
    </div>
  );
};

export default BackstoryAndProduct;
