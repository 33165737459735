import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivacyPolicy from "../modules/static-pages/PrivacyPolicy";
import Jivini from "../modules/jivini";
import TermsAndConditions from "../modules/static-pages/TermsAndConditions";
import RefaundPolicy from "../modules/static-pages/RefaundPolicy";
import ScrollToTop from "../components/ScrollToTop";
const Index = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Jivini />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/refaund-policy" element={<RefaundPolicy />} />
      </Routes>
      <ScrollToTop />
    </Router>
  );
};

export default Index;
