import React from "react";
import HeroSection from "../components/hero-section/hero-section";

const Jivini = () => {
  return (
    <div className="main-container-bg">
      <HeroSection />
    </div>
  );
};

export default Jivini;