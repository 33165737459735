import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="row g-0 footer_bg_img responsive_bg px-lg-5  py-lg-5 py-2 ff_nunito_sans top_ht">
      <div className="col-lg-6 col-xxl-4 px-2 px-sm-5 px-lg-0 px-xxl-5 mt-lg-5 text-light pt-lg-5">
        <p className="fw_700 fs_30 pt-5 text-nowrap ms-lg-1 text-lg-start text-center">
          Portal Links
        </p>
        <div className="d-none d-sm-block">
          <div className="row g-0  align_items p-2">
            <div className="col-4 mt-lg-2 px-1 mt-1 pt-1">
              <p className="fw_700 fs_15 fs_lg_14 fs_md_15 mt-4 text-nowrap">
                Admin Portal
              </p>
              <p className="fw_700 fs_15 fs_lg_14 fs_md_15 mt-2 text-nowrap">
                Manufacturer Portal
              </p>
              <p className="fw_700 fs_15 fs_lg_14 fs_md_15 mt-2 text-nowrap">
                Distributor Portal
              </p>
              <p className="fw_700 fs_15 fs_lg_14 fs_md_15 mt-2 text-nowrap">
                CFA Portal
              </p>
            </div>
            <div className="col-1"></div>
            <div className="col-7 mt-lg-2 mt-1 pt-1 ">
              <p className="fs_15 fs_lg_14 fs_md_15  mt-4 text-nowrap">
                <a
                  target="_blank"
                  className="text-light"
                  href="https://admin.jivini.in/"
                  style={{ textDecoration: "none" }}
                >
                  : admin.jivini.in
                </a>
              </p>
              <p className="fs_15 fs_lg_14 fs_md_15 mt-2 text-nowrap">
                <a
                  target="_blank"
                  className="text-light"
                  href="https://manufacturer.jivini.in/"
                  style={{ textDecoration: "none" }}
                >
                  : manufacturer.jivini.in
                </a>
              </p>
              <p className=" fs_15 fs_lg_14  fs_md_15 mt-2 text-nowrap">
                <a
                  target="_blank"
                  className="text-light"
                  href= "https://distributor.jivini.in/login"
                  style={{ textDecoration: "none" }}
                >
                  : distributor.jivini.in
                </a>
              </p>
              <p className="fs_15 fs_lg_14 fs_md_15  mt-2 text-nowrap">
                <a
                  target="_blank"
                  className="text-light"
                  href="https://cfa.jivini.in/"
                  style={{ textDecoration: "none" }}
                >
                  : cfa.jivini.in
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="d-sm-none">
          <div className="p-2 mx-md-5 mx-lg-0">
            <div
              className="mt-lg-2 px-1 mt-1 pt-1"
              style={{ maxWidth: "420px" }}
            >
              <div className=" my-1">
                <p className="fw_700  text-nowrap col-6">Admin Portal</p>
                <p className="text-nowrap col-6">
                  <a
                    target="_blank"
                    className="text-light"
                    href="https://admin.jivini.in/"
                    style={{ textDecoration: "none" }}
                  >
                    : admin.jivini.in
                  </a>
                </p>
              </div>
              <div className=" my-1">
                <p className="fw_700 mt-2 text-nowrap">Manufacturer Portal</p>
                <p className="mt-2 text-nowrap">
                  <a
                    target="_blank"
                    className="text-light"
                    href="https://manufacturer.jivini.in/"
                    style={{ textDecoration: "none" }}
                  >
                    : manufacturer.jivini.in
                  </a>
                </p>
              </div>
              <div className=" my-1">
                <p className="fw_700 mt-2 text-nowrap">Distributor Portal</p>
                <p className="mt-2 text-nowrap">
                  <a
                    target="_blank"
                    className="text-light"
                    href="https://distributor.jivini.in/login"
                    style={{ textDecoration: "none" }}
                  >
                    : distributor.jivini.in
                  </a>
                </p>
              </div>
              <div className=" my-1">
                <p className="fw_700 mt-2 text-nowrap">CFA Portal</p>
                <p className="mt-2 text-nowrap">
                  <a
                    target="_blank"
                    className="text-light"
                    href="https://cfa.jivini.in"
                    style={{ textDecoration: "none" }}
                  >
                    : cfa.jivini.in
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 mt-lg-5 text-light pt-lg-5  d-none d-xxl-block">
        <div className="d-flex justify-content-center">
          <div>
            <p className="fw_700 fs_30 pt-5 text-nowrap ms-lg-1 text-lg-start text-center text-white">
              Quick Links
            </p>
            <p
              className="text-white fw_700 fs_15 fs_lg_14 fs_md_15 ms-2 cursor_pointer mt-5"
              onClick={() => navigate("/privacy-policy")}
            >
              Privacy Policy
            </p>
            <p
              className="text-white fw_700 fs_15 fs_lg_14 fs_md_15 ms-2 cursor_pointer"
              onClick={() => navigate("/terms-and-conditions")}
            >
              Terms And Conditions
            </p>
            <p
              className="text-white fw_700 fs_15 fs_lg_14 fs_md_15 ms-2 cursor_pointer"
              onClick={() => navigate("/refaund-policy")}
            >
              Refund and Cancellation Policy​
            </p>
          </div>
        </div>
      </div>

      <div className="col-lg-6 col-xxl-5 mt-lg-5 mt-2 ps-lg-5 pt-lg-5 text-light px-2">
        <div className="text-white mt-5 mx-sm-5 mx-lg-0 mx-xxl-5 mx-2 bg_footer shadow ms-xxl-5 mb-5 contact_us rounded">
          <div className="text-start px-4 py-4 me-lg-5 me-md-3 t-5">
              <h5 class=" fw_700 fs_25 fs_md_18">Contact Us</h5>
              <p class=" text-color fw_400 fs_18">
                <div className="d-flex align-items-center text-light mt-4">
                  <i class="ri-phone-line"></i>
                  <div className="fw_400 fs_18 fs_md_16 ms-3">
                    +91 9035338640
                  </div>
                </div>
                <div className="d-flex align-items-center text-light mt-2">
                  <i class="ri-mail-line"></i>
                  <div className="fw_400 fs_18 fs_md_16 ms-3">
                    support@jivini.in
                  </div>
                </div>
                <div className="d-flex text-light mt-2">
                  <i class="ri-map-pin-line"></i>
                  <div className="fw_400 fs_18 fs_md_16 ms-3">
                    JIVINI PRIVATE LIMITED, <br />
                    #12, 2nd Floor, 4th cross, model House Street, Indiranagar,
                    Mysore-570010 Karnataka, India.
                  </div>
                </div>
              </p>
          </div>
          <span className="fw_700 fs_25"></span>
        </div>
      </div>
      <div className="text-light d-block d-xxl-none">
        <div className="mx-sm-5 mx-3 mx-lg-0 mx-xxl-5">
          <p className="fw_700 fs_30 text-nowrap ms-lg-1 text-lg-start text-white">
            Quick Links
          </p>
          <p
            className="text-white fw_700 fs_15 fs_lg_14 fs_md_15 ms-2 cursor_pointer"
            onClick={() => navigate("/privacy-policy")}
          >
            Privacy Policy
          </p>
          <p className="text-white fw_700 fs_15 fs_lg_14 fs_md_15 ms-2 cursor_pointer">
            Terms And Conditions
          </p>
          <p
            className="text-white fw_700 fs_15 fs_lg_14 fs_md_15 ms-2 cursor_pointer"
            onClick={() => navigate("/refaund-policy")}
          >
            Refund and Cancellation Policy​
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
