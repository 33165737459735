import React from "react";
import PinImg from "../../assets/images/pin.png";
import PantonImg from "../../assets/images/pantone.png";
import GeoImg from "../../assets/images/geo.png";
import MagnetismImg from "../../assets/images/magnetism.png";
import ConnectionImg from "../../assets/images/connection.png";
import EffeciencyImg from "../../assets/images/efficiency.png";
import ShieldImg from "../../assets/images/shield.png";
import AnalysisImg from "../../assets/images/analysis.png";
import DistributerImg from "../../assets/icons/distrubuter-icon.svg";
import EmpManageImg from "../../assets/icons/employee-management-icon.svg";
import { motion } from "framer-motion";

const FeaturesAndAbility = () => {
  const array = [
    {
      image: MagnetismImg,
      heading: "Controlled Sales Generation",
      title:
        " Now you can plan and execute discounted sales and control infiltration / pilferage of products within defined territories.",
    },
    {
      image: EffeciencyImg,
      heading: "Efficiency in all DOMAINS",
      title:
        "We have organized and optimized every process and step under ordering, approving and managing discounted sales",
    },
    {
      image: ConnectionImg,
      heading: "Transparency & Accessibility",
      title:
        "With Data right down to the district and doctor wise reports, you are now equipped with better insights.",
    },
  ];
  const cards = [
    {
      image: GeoImg,
      text: "Geo Tagging of  Photos",
    },
    {
      image: PinImg,
      text: "Multi Level OTP Verification",
    },
    {
      image: PantonImg,
      text: "Color Coded Order Status Updates",
    },
  ];
  return (
    <>
      <div className="row g-0 my-lg-5 mt-5 my-2 text-center mx-md-5 p-2 px-3 px-lg-5">
        <div className="fw_700 fs_40 fs_sm_25 my-lg-5 my-2">
          Features & Abilities
        </div>
        <div className="d-flex flex-wrap justify-content-center g-1">
          {array?.map((items, index) => (
            <>
              <motion.div
                viewport={{ once: true }}
                initial={{ opacity: 0 }}
                animate="visible"
                whileInView={{
                  opacity: 1,
                  transition: { delay: index * 0.1 },
                }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 1.05 }}
                class="col-xl-4 col-md-6 mt-1 d-flex align-items-stretch my-2"
                style={{ maxWidth: "420px" }}
              >
                <div className="text-start px-4 py-5 me-lg-5 me-md-3 border border_radius">
                  <img src={items?.image} height={50} className="mb-4" />
                  <div class="">
                    <h5 class=" fw_700 fs_22 fs_sm_18">{items?.heading}</h5>
                    <p class=" text-color fw_400 fs_18 fs_sm_16">
                      {items?.title}
                    </p>
                  </div>
                </div>
              </motion.div>
            </>
          ))}
        </div>
        <div className="d-flex flex-wrap justify-content-center g-1">
          {cards?.map((item, index) => (
            <>
              <motion.div
                viewport={{ once: true }}
                initial={{ opacity: 0 }}
                animate="visible"
                whileInView={{
                  opacity: 1,
                  transition: { delay: index * 0.1 },
                }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 1.05 }}
                className="col-xl-4 col-md-6 col-12 mt-lg-5 mt-2 "
                style={{ maxWidth: "420px" }}
              >
                <div className="h-100 d-flex align-items-center px-4 py-3 me-lg-5 me-md-2 card_bg border_radius">
                  <div className="me-3">
                    <img src={item?.image} height={50} />
                  </div>
                  <div className="fw_700 fs_22 fs_sm_18 text-start">
                    {item?.text}
                  </div>
                </div>
              </motion.div>
            </>
          ))}
        </div>

        <div className="my-5 my-2">
          <div className="fw_700 mt-lg-5 pt-lg-5">
            <p className="w-100 fs_50 fs_xl_40 fs_lg_30 fs_md_25 mx-auto">
              Strengths & Fortification
            </p>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <div className="col-xxl-10 mx-auto">
              <div className="row gap-2">
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 1.05 }}
                  className="d-sm-flex px-3 py-4 border border_radius card-container col-lg-10 col-xl-5 col-12 mx-lg-auto"
                >
                  <div className="mx-3 me-4 text-start">
                    <img src={ShieldImg} height={50} className="my-2" />
                  </div>
                  <div className="fw_700 fs_22 fs_sm_18 text-start">
                    Authentication
                    <br />
                    <span className="fw_400 fs_18 fs_sm_16 text-color">
                      With our multi step authentication, your data can now be
                      accessed only by people you allow.
                    </span>
                  </div>
                </motion.div>

                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 1.05 }}
                  className="d-sm-flex px-3 py-4 border border_radius card-container col-lg-10 col-xl-5 col-12 mx-auto"
                >
                  <div className="mx-3 me-4 text-start">
                    <img src={AnalysisImg} height={50} className="my-2" />
                  </div>
                  <div className="fw_700 fs_22 text-start">
                    Analysis
                    <br />
                    <span className="fw_400 fs_18 text-color">
                      We provide you with the data that matters and is most
                      importantly relevant to you. P.S. Of course you can check
                      out the "whole of the data as well!"
                    </span>
                  </div>
                </motion.div>

                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 1.05 }}
                  className=" d-sm-flex px-3 py-4 border border_radius card-container mt-xl-5 col-lg-10 col-xl-5 col-12 mx-auto"
                  // style={{paddingBottom:"2.5rem"}}
                >
                  <div className="mx-3 me-4 text-start">
                    <img src={EmpManageImg} height={50} className="my-2" />
                  </div>
                  <div className="fw_700 fs_22 text-start">
                    Employee Management
                    <br />
                    <span className="fw_400 fs_18 text-color">
                      We provide you with the data that matters and is most
                      importantly relevant to you. P.S. Of course you can check
                      out the “whole of the data as well!
                    </span>
                  </div>
                </motion.div>

                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 1.05 }}
                  className="d-sm-flex px-3 py-4 mt-xl-5 border border_radius card-container col-lg-10 col-xl-5 col-12 mx-auto"
                >
                  <div className="mx-3 me-4 text-start">
                    <img src={DistributerImg} height={50} className="my-2" />
                  </div>
                  <div className="fw_700 fs_22 text-start">
                    Distributor’s Reimbursements
                    <br />
                    <span className="fw_400 fs_18 text-color">
                      Now, track every offer / discount given on your product,
                      supplied by the distributor, and reimburse accordingly.
                    </span>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturesAndAbility;
