import React from "react";
import "./hero-section.css";
import Logo from "../../assets/icons/Logo.svg";
import Peaceimg from "../../assets/icons/peace.svg";
import BackstoryAndProduct from "../backstoryAndProduct";
import FeaturesAndAbility from "../features/featuresAndAbility";
import Footer from "../features/footer";
import { AnimatePresence, motion } from "framer-motion";
const HeroSection = () => {
  const headingVariants = {
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    hidden: { opacity: 0, y: -20 },
  };

  const video =
    "https://jivini-live.s3.ap-south-1.amazonaws.com/jivni_vedio.mp4";

  return (
    <div className="ff_nunito_sans hero-section-main-container w-100">
      <div>
        <nav
          class="navbar fixed-top  top-container-fluid"
          style={{ background: "white" }}
        >
          <motion.div
            variants={headingVariants}
            initial="hidden"
            animate="visible"
          >
            <img src={Logo} className="navbar-brand px-4" height={35} />
          </motion.div>
        </nav>
      </div>
      <div className="d-flex align-items-xl-center h-100">
        <div className="d-xl-flex align-items-center justify-content-between w-100 px-2">
          <div className="col-xxl-4 col-12 text-start hero-heading-container mx-auto my-4">
            <div className="ms-sm-4 ps-2 ms-xxl-0 ps-xl-0">
              <motion.div
                variants={{
                  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
                  hidden: { opacity: 0, y: -20 },
                }}
                initial="hidden"
                animate="visible"
                className="d-flex align-items-center bg_blue px-lg-3 py-2 rounded w-100"
                style={{ maxWidth: "220px" }}
              >
                <div className="wave ms-1 ms-lg-0">
                  <img src={Peaceimg} height={30} className="peace-icon" />
                </div>
                <div className="fs_18 blue_text fw_700 mx-2 pt-1 text-nowrap">
                  Introducing Jivini
                </div>
              </motion.div>
              <motion.div
                variants={{
                  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
                  hidden: { opacity: 0, y: 20 },
                }}
                initial="hidden"
                animate="visible"
                className="fs_50 fs_xl_40 fs_lg_30 fs_md_25 fs_sm_18 fw_700 line_ht mt-2"
              >
                An environment created for approvals
              </motion.div>
            </div>
          </div>

          <div className="col-xl-7">
            <motion.div
              variants={{
                visible: { opacity: 1, x: 0, transition: { ease: "easeOut", duration: 0.8 } },
                hidden: { opacity: 0, x: 100 },
              }}
              initial="hidden"
              animate="visible"
              className="text-xl-start text-center p-2 px-sm-4"
            >
              <video controls className="video-container">
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </motion.div>
          </div>
        </div>
      </div>
      <>
        <BackstoryAndProduct />
        <FeaturesAndAbility />
        <Footer />
      </>
    </div>
  );
};

export default HeroSection;
