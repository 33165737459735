import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Logo from "../../assets/icons/Logo.svg";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/features/footer";
const PrivacyPolicy = () => {
  const headingVariants = {
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    hidden: { opacity: 0, y: -20 },
  };
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <nav
          class="navbar fixed-top  top-container-fluid"
          style={{ background: "white" }}
        >
          <motion.div
            variants={headingVariants}
            initial="hidden"
            animate="visible"
          >
            <img
              onClick={() => navigate("/")}
              src={Logo}
              className="navbar-brand px-4 cursor_pointer"
              height={35}
            />
          </motion.div>
        </nav>
      </div>
      <h1 className="mt-5 text-center pt-3">Privacy Policy</h1>
      <div className="d-flex justify-content-center mx-4 mx-md-0">
        <div className="col-md-8 col-12">
          <p className="mt-4">
            <strong className="primary_color fs_18 fs_sm_16">Disclaimer: </strong>
            <span className="fs_18 fs_sm_16 primary_color">
              Please read and edit the Privacy Policy given below as per your
              /website’s requirements. Don’t use or apply these as-is the basis
              on your website.
            </span>
          </p>
          <p>
            <strong className="primary_color fs_18 fs_sm_16">Privacy Policy</strong>
          </p>
          <p>
            <strong className="primary_color fs_18 fs_sm_16">
              Your website may use the Privacy Policy given below:
            </strong>
          </p>
          <p className="fs_18 fs_sm_16 primary_color">
            The terms “We” / “Us” / “Our”/”Company” individually and
            collectively refer to Jivini and the terms “You” /”Your” /
            “Yourself” refer to the users.
          </p>
          <p className="fs_18 fs_sm_16 primary_color">
            This Privacy Policy is an electronic record in the form of an
            electronic contract formed under the Information Technology Act,
            2000 and the rules made thereunder and the amended provisions
            pertaining to electronic documents/records in various statutes as
            amended by the Information Technology Act, 2000. This Privacy Policy
            does not require any physical, electronic, or digital signature.
          </p>
          <p className="fs_18 fs_sm_16 primary_color">
            This Privacy Policy is a legally binding document between you and
            Jivini (both terms defined below). The terms of this Privacy
            Policy will be effective upon your acceptance of the same (directly
            or indirectly in electronic form, by clicking on the I accept tab or
            by use of the website or by other means) and will govern the
            relationship between you and Jivini for your use of the website
            “Website” (defined below)
          </p>
          <p className="fs_18 fs_sm_16 primary_color">
            This document is published and shall be construed in accordance with
            the provisions of the Information Technology (Reasonable security
            practices and procedures and sensitive personal data of information)
            rules, 2011 under Information Technology Act, 2000; that require
            publishing of the Privacy Policy for collection, use, storage and
            transfer of sensitive personal data or information.
          </p>
          <p className="fs_18 fs_sm_16 primary_color">
            Please read this Privacy Policy carefully by using the Website, you
            indicate that you understand, agree, and consent to this Privacy
            Policy. If you do not agree with the terms of this Privacy Policy,
            please do not use this Website.
          </p>
          <p className="fs_18 fs_sm_16 primary_color">
            By providing us your Information or by making use of the facilities
            provided by the Website, You hereby consent to the collection,
            storage, processing, and transfer of any or all of Your Personal
            Information and Non-Personal Information by us as specified under
            this Privacy Policy. You further agree that such collection, use,
            storage, and transfer of Your Information shall not cause any loss
            or wrongful gain to you or any other person.
          </p>
          <p className="mt-4">
            <strong className="primary_color fs_18 fs_sm_16">USER INFORMATION</strong>
          </p>
          <p className="fs_18 fs_sm_16 primary_color">
            To avail certain services on our Websites, users are required to
            provide certain information for the registration process namely: –
            a) your name, b) email address, c) sex, d) age, e) PIN code, f)
            credit card or debit card details g) medical records and history h)
            sexual orientation, i) biometric information, j) password, etc.,
            and/or your occupation, interests, and the like. The Information
            supplied by the users enables us to improve our sites and provide
            you with the most user-friendly experience
          </p>
          <p className="fs_18 fs_sm_16 mb-4 primary_color py-3">
            All required information is service dependent and we may use the
            above-said user information to, maintain, protect, and improve its
            services (including advertising services) and for developing new
            services
          </p>
          <p className="fs_18 fs_sm_16 mb-4 primary_color pb-3">
            Such information will not be considered sensitive if it is freely
            available and accessible in the public domain or is furnished under
            the Right to Information Act, 2005 or any other law for the time
            being in force.
          </p>
          <span className="fs_18 fs_sm_16 primary_color">COOKIES</span>
          <p className="fs_18 fs_sm_16 primary_color">
            To improve the responsiveness of the sites for our users, we may use
            “cookies”, or similar electronic tools to collect information to
            assign each visitor a unique, random number as a User Identification
            (User ID) to understand the user’s individual interests using the
            Identified Computer. Unless you voluntarily identify yourself
            (through registration, for example), we will have no way of knowing
            who you are, even if we assign a cookie to your computer. The only
            personal information a cookie can contain is information you supply
            (an example of this is when you ask for our Personalised Horoscope).
            A cookie cannot read data off your hard drive. Our advertisers may
            also assign their own cookies to your browser (if you click on their
            ads), a process that we do not control.
          </p>
          <p className="fs_18 fs_sm_16 primary_color pb-4">
            Our web servers automatically collect limited information about your
            computer’s connection to the Internet, including your IP address,
            when you visit our site. (Your IP address is a number that lets
            computers attached to the Internet know where to send you data —
            such as the web pages you view.) Your IP address does not identify
            you personally. We use this information to deliver our web pages to
            you upon request, to tailor our site to the interests of our users,
            measure traffic within our site, and let advertisers know the
            geographic locations from where our visitors come.
          </p>
          <strong className="fs_18 fs_sm_16 primary_color">
            LINKS TO THE OTHER SITES
          </strong>
          <p className="fs_18 fs_sm_16 primary_color pb-3">
            Our policy discloses the privacy practices for our own website only.
            Our site provides links to other websites also that are beyond our
            control. We shall in no way be responsible in way for your use of
            such sites.<strong>5.</strong>
          </p>

          <strong className="fs_18 fs_sm_16 primary_color">INFORMATION SHARING</strong>
          <p className="fs_18 fs_sm_16 primary_color">
            We share the sensitive personal information with any third party
            without obtaining the prior consent of the user in the following
            limited circumstances:
          </p>
          <p className="fs_18 fs_sm_16 primary_color py-2">
            (a) When it is requested or required by law or by any court or
            governmental agency or authority to disclose, for the purpose of
            verification of identity, or for the prevention, detection,
            investigation including cyber incidents, or for prosecution and
            punishment of offenses. These disclosures are made in good faith and
            belief that such disclosure is reasonably necessary for enforcing
            these Terms; for complying with the applicable laws and regulations.
          </p>
          <p className="fs_18 fs_sm_16 primary_color py-2">
            (b) We propose to share such information within its group companies
            and officers and employees of such group companies for the purpose
            of processing personal information on its behalf. We also ensure
            that these recipients of such information agree to process such
            information based on our instructions and in compliance with this
            Privacy Policy and any other appropriate confidentiality and
            security measures.
          </p>
          <span className="fs_18 fs_sm_16 primary_color">INFORMATION SECURITY</span>
          <p className="fs_18 fs_sm_16 primary_color">
            We take appropriate security measures to protect against
            unauthorized access to or unauthorized alteration, disclosure, or
            destruction of data. These include internal reviews of our data
            collection, storage and processing practices, and security measures,
            including appropriate encryption and physical security measures to
            guard against unauthorized access to systems where we store personal
            data.
          </p>
          <p className="fs_18 fs_sm_16 primary_color py-3">
            All information gathered on our Website is securely stored within
            our controlled database. The database is stored on servers secured
            behind a firewall; access to the servers is password-protected and
            is strictly limited. However, as effective as our security measures
            are, no security system is impenetrable. We cannot guarantee the
            security of our database, nor can we guarantee that information you
            supply will not be intercepted while being transmitted to us over
            the Internet. And, of course, any information you include in a
            posting to the discussion areas is available to anyone with Internet
            access.
          </p>
          <p className="fs_18 fs_sm_16 primary_color py-3">
            However, the internet is an ever-evolving medium. We may change our
            Privacy Policy from time to time to incorporate necessary future
            changes. Of course, our use of any information we gather will always
            be consistent with the policy under which the information was
            collected, regardless of what the new policy may be.
          </p>
          <span className="fs_18 fs_sm_16 primary_color">Grievance Redressal</span>
          <p className="fs_18 fs_sm_16 primary_color">
            Redressal Mechanism: Any complaints, abuse, or concerns with regards
            to content and or comment or breach of these terms shall be
            immediately informed to the designated Grievance Officer as
            mentioned below in writing or through email signed with the
            electronic signature to Mohit Mittal (“Grievance Officer”).
          </p>
          <p className="fs_18 fs_sm_16 primary_color">
            Mr. Rajesh Jain <strong>(Grievance Officer)</strong>
            <br />
            JIVNI PRIVATE LIMITED
            <br />
            CIN:U72900KA2022PTC164953
            <br /> #12, 2nd Floor, 4th cross,
            <br /> model House Street,
            <br /> Indiranagar,
            <br /> Mysore-570010 Karnataka, India.
            <br />
            Company Name & Address
            <br />
            <label className="pt-4">
            Email: <a href="mailto:support@jivini.in">support@jivini.in</a> <br/>Ph:  +91 90353 38640
            </label>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
