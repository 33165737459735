import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Logo from "../../assets/icons/Logo.svg";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/features/footer";
const RefaundPolicy = () => {
  const headingVariants = {
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    hidden: { opacity: 0, y: -20 },
  };
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <nav
          class="navbar fixed-top  top-container-fluid"
          style={{ background: "white" }}
        >
          <motion.div
            variants={headingVariants}
            initial="hidden"
            animate="visible"
          >
            <img
              onClick={() => navigate("/")}
              src={Logo}
              className="navbar-brand px-4 cursor_pointer"
              height={35}
            />
          </motion.div>
        </nav>
      </div>
      <h1 className="mt-5 text-center pt-3">Refund and Cancellation Policy</h1>
      <div className="d-flex justify-content-center mx-4 mx-md-0 mt-5 mb-5">
        <div className="col-md-8 col-12">
          <p className="fs_18 fs_sm_16 primary_color pt-4">
            Our focus is complete customer satisfaction. In the event, if you
            are displeased with the services provided, we will refund back the
            money, provided the reasons are genuine and proved after
            investigation. Please read the fine prints of each deal before
            buying it, it provides all the details about the services or the
            product you purchase.
          </p>
          <p className="fs_18 fs_sm_16 primary_color pt-4 pb-3">
            In case of dissatisfaction from our services, clients have the
            liberty to cancel their projects and request a refund from us. Our
            Policy for the cancellation and refund will be as follows:
          </p>
          <strong className="fs_18 fs_sm_16 primary_color">
            Cancellation Policy
          </strong>
          <p className="fs_18 fs_sm_16 primary_color pt-4">
            For Cancellations please contact the us via contact us link.
          </p>
          <p className="fs_18 fs_sm_16 primary_color pt-4 pb-3">
            Requests received later than 1 business days prior to the end of the
            current service period will be treated as cancellation of services
            for the next service period.
          </p>
          <strong className="fs_18 fs_sm_16 primary_color pt-4">
            Refund Policy
          </strong>
          <p className="fs_18 fs_sm_16 primary_color pt-4 pb-3">
            We will try our best to create the suitable design concepts for our
            clients.
          </p>
          <p className="fs_18 fs_sm_16 primary_color pt-1 pb-3">
            In case any client is not completely satisfied with our products we
            can provide a refund.
          </p>
          <p className="fs_18 fs_sm_16 primary_color pt-1 pb-3">
            If paid by credit card, refunds will be issued to the original
            credit card provided at the time of purchase and in case of payment
            gateway name payments refund will be made to the same account.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default RefaundPolicy;
